import React from "react";
import { NextPage } from "next";

type IErrorProps = {
  statusCode: number | undefined;
};

const ErrorPage: NextPage<IErrorProps> = () => (
  <>
    <div>error</div>
  </>
);

ErrorPage.getInitialProps = ({ req, res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  console.error(`进入_error [${statusCode}] URL：${req?.url}`);

  return { statusCode };
};

export default ErrorPage;
